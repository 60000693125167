.dynamic-sidebar-item-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 60px;

  padding-left: 16px;
  padding-right: 16px;

  font-size: 24px;
  font-weight: 300;
}

.dynamic-sidebar-item-root:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}
