.clientcardview-root {
  min-width: 480px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: #fff;

  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 32px;

  margin-right: 16px;
  margin-bottom: 16px;
}

.clientcardview-root:hover {
  background-color: #ddd;
  cursor: pointer;
}

.clientcardview-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 8px;
}

.clientcardview-field-small {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 4px;
}

.clientcardview-label {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.clientcardview-label-small {
  font-size: 12px;
  color: #666;
  display: flex;
  flex-direction: row;
}

.clientcardview-value {
  font-size: 18px;
  font-weight: 500;
  color: #222;
}

.clientcardview-clickable {
  text-decoration: underline;
  color: teal;
}

.clientcardview-clickable:hover {
  cursor: pointer;
}