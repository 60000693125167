.navbar-label {
  color: white;
  font-size: 28px;
  font-weight: 300;
}

.navbar-label:hover {
  cursor: pointer;
}

.navbar-middle-container {
  min-width: 256px;
  max-width: 512px;
}