.select {
  font-size: 14px;
  color: #000;
  height: 36px;
  width: 100%;
  border: 1px solid #7c1111;
  border-radius: 6px;

  padding-left: 16px;
  padding-right: 16px;
}

.select-disabled {
  font-size: 14px;
  color: #000;
  height: 36px;
  width: 100%;
  border: 1px solid #7c1111;
  border-radius: 6px;
  background-color: #c6c6c6;

  padding-left: 16px;
  padding-right: 16px;

  cursor: not-allowed;
}