.navbar-layout-root {
  width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  justify-content: flex-start;
  align-items: flex-start;
}

.navbar-layout-container {
  display: flex;
  flex-direction: row;
  flex: 1;

  width: 100%;
  background-color: #f8f8f8;

  justify-content: flex-start;
  align-items: flex-start;
}