.sidebar-layout-root {
  display: flex;
  flex-direction: row;
  flex: 1;

  width: 100%;
  background-color: #f8f8f8;

  align-self: stretch;

  justify-content: flex-start;
  align-items: flex-start;
}