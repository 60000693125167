.clientcardroutesplitview-root {
  min-width: 480px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: #fff;

  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 32px;

  margin-right: 16px;
  margin-bottom: 16px;
}

.clientcardroutesplitview-root:hover {
  background-color: #ddd;
  cursor: pointer;
}

.clientcardroutesplitview-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 16px;
}

.clientcardroutesplitview-field-small {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 4px;
}

.clientcardroutesplitview-input {
  font-size: 14px;
  color: #000;
  height: 36px;
  width: 100%;
  border: 1px solid #7c1111;
  border-radius: 6px;

  padding-left: 16px;
  padding-right: 16px;
}

.clientcardroutesplitview-button {
  font-size: 14px;
  color: white;
  height: 36px;
  width: 100%;
  background-color: #7c1111;
  border: 1px solid #7c1111;
  border-radius: 6px;

  padding-left: 16px;
  padding-right: 16px;
}

.clientcardroutesplitview-button:hover {
  cursor: pointer;
  background-color: #b81111;
}

.clientcardroutesplitview-label {
  color: #333;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
}

.clientcardroutesplitview-label-small {
  font-size: 12px;
  color: #666;
  display: flex;
  flex-direction: row;
}

.clientcardroutesplitview-value {
  font-size: 14px;
  font-weight: 500;
  color: #222;
}

.clientcardroutesplitview-clickable {
  text-decoration: underline;
  color: teal;
}

.clientcardroutesplitview-clickable:hover {
  cursor: pointer;
}

.clientcardroutesplitview-select {
  font-size: 14px;
  color: #000;
  height: 36px;
  width: 100%;
  border: 1px solid #7c1111;
  border-radius: 6px;

  padding-left: 16px;
  padding-right: 16px;
}

.clientcardroutesplitview-option {
  font-size: 14px;
  color: #000;
  height: 36px;
  width: 100%;
  border: 1px solid #7c1111;
  border-radius: 6px;

  padding-left: 16px;
  padding-right: 16px;
}