.tab-layout-root {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  background-color: #f8f8f8;

  align-self: stretch;

  justify-content: flex-start;
  align-items: flex-start;
}

.tab-layout-tab-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  overflow: scroll;
}

.tab-layout-tab-bar::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.tab-layout-tab-bar-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
}

.tab-layout-tab-bar-item:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.tab-layout-tab-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  align-self: stretch;
}