.dynamic-sidebar-expand-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dynamic-sidebar-expand-container {
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  
  padding-left: 16px;
  padding-right: 16px;
}

.dynamic-sidebar-expand-container:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.dynamic-sidebar-expand-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  padding-left: 16px;
  padding-right: 16px;
}

.dynamic-sidebar-expand-label {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 60px;

  font-size: 24px;
  font-weight: 300;
}