.dropdown-item {
  height: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}