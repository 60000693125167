.button-outline-root {
  height: 36px;
  border: 1px solid #7c1111;
  border-radius: 18px;

  font-size: 14px;
  font-weight: 500;
  color: #7c1111;
}

.button-outline-root:hover {
  cursor: pointer;
  border: 1px solid #b81111;
  background-color: #ffaaaa;
}