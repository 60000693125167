body, div, a, h1, h2, h3, h4, h5, h6, span, label {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  
  display: flex;
  flex-direction: column;
}

input, button {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.main {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
}

.main-container {
  display: flex;
  flex-direction: row;
  flex: 1;

  width: 100%;
  background-color: #d3d3d4;

  justify-content: flex-start;
  align-items: flex-start;
}