.page-client-root {
  width: 100%;
  min-height: 100vh;

  background-color: #f3f3f4;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  
  padding-top: 16px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
}

.page-client-label {
  font-size: 24px;
  font-weight: 300;
  color: #333;
}

.page-client-role-container {
  min-width: 300px;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  background-color: white;
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
}

.page-client-role-container:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.page-client-role-item:hover {
  cursor: pointer;
}