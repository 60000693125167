.button-outline-small-root {
  height: 24px;
  border: 1px solid #7c1111;
  border-radius: 12px;

  font-size: 10px;
  font-weight: 500;
  color: #7c1111;
  margin-right: 8px;
}

.button-outline-small-root:hover {
  cursor: pointer;
  border: 1px solid #b81111;
  background-color: #ffaaaa;
}