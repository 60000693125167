.dynamic-tabbar-root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  overflow: scroll;
}

.dynamic-tabbar-root::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}