.messaging-root {
  display: flex;
  flex-direction: column;

  width: 100px;
  min-height: 100%;

  flex: 1;
  align-self: stretch;
}

.messaging-header {
  padding-left: 16px;
  padding-right: 16px;
}