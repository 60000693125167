.image-button-outline-small-root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-button-outline-small-root:hover {
  cursor: pointer;
  background-color: #c6c6c6;
}