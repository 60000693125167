.panel-root {
  min-width: 450px;
  max-width: 600px;
  min-height: 100vh;

  background-color: white;

  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  box-shadow: -2px 0 30px #333;
}

.panel-nav-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.panel-nav-close {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  color: darkred;
  text-decoration: underline;
  font-weight: 700;
}

.panel-nav-close:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}