.button-text-root {
  width: 100%;
  height: 36px;
  border: none;

  font-size: 14px;
  font-weight: 500;
  color: #7c1111;
  background-color: transparent;
}

.button-text-root:hover {
  cursor: pointer;
  color: #b81111;
}