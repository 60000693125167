.quickstart-section {
  border-left: 1px solid #c6c6c6;
  padding-left: 16px;
}

.quickstart-button {
  font-size: 14px;
  color: white;
  height: 36px;
  width: 100%;
  background-color: #7c1111;
  border: 1px solid #7c1111;
  border-radius: 6px;

  padding-left: 16px;
  padding-right: 16px;
}

.quickstart-button:hover {
  cursor: pointer;
  background-color: #b81111;
}