.balancecardview-root {
  min-height: 72px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
}

.balancecardview-label {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 8px;
}

.balancecardview-value {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-top: 0;
  margin-bottom: 4px;
}

.balancecardview-action-container {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 16px;
}