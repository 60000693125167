.client-banner {
  max-width: 600px;
  font-size: 14px;
  font-weight: 500;
  color: #0f299b;
  background-color: #cfe2ff;
  border: 1px solid #0f299b;
  border-radius: 6px;

  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  
  margin-bottom: 16px; 
}

.client-banner-danger {
  color: #9b0f0f;
  background-color: #ffcfcf;
  border: 1px solid #9b0f0f;
}

.client-button {
  max-width: 600px;
}