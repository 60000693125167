.dynamic-tabbar-item-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  flex-shrink: 0;
}

.dynamic-tabbar-item-root:hover {
  cursor: pointer;
  background-color: #c6c6c6;
}

.dynamic-tabbar-item-content {
  font-size: 14px;
  font-weight: 400;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;

}

.dynamic-tabbar-item-selected {
  width: 100%;
  height: 4px;
  background-color: brown;
}

.dynamic-tabbar-item-not-selected {
  width: 100%;
  height: 4px;
  background-color: transparent;
}