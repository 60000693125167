.register-outer {
  width: 100%;
  height: 100vh;
  background-color: brown;

  display: flex;
  align-items: center;
  justify-content: center;
}

.register-inner {
  width: 480px;
  min-height: 320px;
  background-color: white;

  border-radius: 6px;

  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 32px;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;

}

.register-header {
  font-size: 28px;
  font-weight: 300;

  margin-bottom: 32px;
}

.register-field {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 16px;
}

.register-label {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}

.register-input-text {
  width: 100%;
  min-height: 40px;
  border: 1px solid #777777;
  border-radius: 6px;

  padding-left: 8px;
  padding-right: 8px;
}

.register-button {
  width: 100%;
  min-height: 48px;

  color: white;
  background-color: #7c1111;
  border: 1px solid #7c1111;
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-bottom: 16px;
}

.register-button:hover {
  background-color: #b81111;
  border: 1px solid #b81111;
}

.register-text-button {
  width: 100%;
  min-height: 48px;

  color: #7c1111;
  border: none;
  background-color: transparent;
  border-radius: 6px;

  text-decoration: underline;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.register-text-button:hover {
  background-color: #e6e6e6;
}

.clickable {
  cursor: pointer;
}