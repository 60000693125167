.back-content-layout-root {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;

  align-self: stretch;
}

.back-button-container {
  width: 100%;
}

.back-button {
  width: 100px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;

  font-size: 14px;
  color: #333333;
}

.back-button:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.back-content-layout-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  display: flex;
  flex-direction: column;
  align-self: stretch;
}