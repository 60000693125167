.loading-root {
  width: 200px;
  height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 6px;
}

.loading-none {
  display: none;
}