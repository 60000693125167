.navbar-root {
  width: 100%;
  height: 48px;

  background-color: brown;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 16px;
  padding-right: 16px;
}